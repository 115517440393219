// extracted by mini-css-extract-plugin
export var alignLeft = "B_qh d_fp d_bG d_dv";
export var alignCenter = "B_bP d_fq d_bD d_dw";
export var alignRight = "B_qj d_fr d_bH d_dx";
export var verticalSolidComponent = "B_tK";
export var compContent = "B_tD d_r";
export var normal = "B_tL d_Z d_cb";
export var reverse = "B_tM d_Z d_bK";
export var last = "B_tF";
export var empty = "B_tN d_r";
export var empty2 = "B_tP d_bw";
export var borderRight = "B_tQ d_b8 d_c1";
export var borderLeft = "B_tR d_b9 d_c1";