// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "r_ry d_gv d_cs";
export var heroHeaderCenter = "r_gw d_gw d_cs d_dw";
export var heroHeaderRight = "r_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "r_rz d_gr d_cw";
export var heroParagraphCenter = "r_gs d_gs d_cw d_dw";
export var heroParagraphRight = "r_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "r_rB d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "r_rC d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "r_rD d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "r_rF d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "r_rG d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "r_rH y_rH";
export var heroExceptionNormal = "r_rJ y_rJ";
export var heroExceptionLarge = "r_rK y_rK";
export var Title1Small = "r_rL y_rL y_sr y_ss";
export var Title1Normal = "r_rM y_rM y_sr y_st";
export var Title1Large = "r_rN y_rN y_sr y_sv";
export var BodySmall = "r_rP y_rP y_sr y_sL";
export var BodyNormal = "r_rQ y_rQ y_sr y_sM";
export var BodyLarge = "r_rR y_rR y_sr y_sN";