// extracted by mini-css-extract-plugin
export var alignLeft = "z_qh d_fp d_bG d_dv";
export var alignCenter = "z_bP d_fq d_bD d_dw";
export var alignRight = "z_qj d_fr d_bH d_dx";
export var verticalDottedFirstComponent = "z_tz";
export var wrap = "z_tB d_bJ d_cb";
export var topPadding = "z_tC d_cX";
export var compContent = "z_tD d_w";
export var last = "z_tF";
export var box = "z_tG d_cr";
export var boxWrapper = "z_tH d_w d_bz";
export var boxWrapperFirst = "z_tJ d_w d_bz";